import React from 'react';
import PageList from '../../components/PageListAPI'
import { getTender } from '../../api/tender.api'
import { TENDER_STATUS } from '../tenderOur/TenderOurList'

const columns = [
  { id: 'statusRus', name: 'Статус' },
  { id: 'name', name: 'Название' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', type: 'date',  formatDate:'LLL' },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', type: 'date',  formatDate:'LLL' },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', type: 'date',  formatDate:'LLL' },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', type: 'date',  formatDate:'LLL' },
]

export default function TenderOurList(props) {
  return (
    <div className='App-paper'>
      <PageList
        title='Тендеры'
        name='tender-our-list'
        columns={columns}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getTender,
          transform: (res)=>{
            return {...res, 
              get: res.get.map(e => ({ 
                ...e, 
                typeTenderRus: e.typeTender==='open'?'открытый':'закрытый',
                statusRus: TENDER_STATUS.find(s=>s.value===e.status).label 
              })) }
          }
        }}
        {...props}
      />
    </div>
  )
};