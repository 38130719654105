import React, { useEffect, useState } from 'react';
import {
  Button, Tabs, Tab, Typography, Table, Input,
  TableBody, TableCell, TableHead, TableRow, TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { getTenderById, setTenderBid, updTenderEstimate, createTenderProject } from '../../api/tender.api'
import moment from 'moment'
import { TENDER_STATUS } from '../tenderOur/TenderOurList'


export const TENDER_STATUS_BID = [
  { value: 'none', label: 'не подана' },
  { value: 'set', label: 'заявка подана' },
  { value: 'confirm', label: 'заявка принята' },
  { value: 'rejected', label: 'заявка отклонена' },
]

const formName = 'TenderOurOneHeader'
const formContacts = 'TenderContact'
const formLots = 'TenderLots'
export default function TenderOurOne({ match, history }) {
  const dispatch = useDispatch()
  const [tabsId, setTabsId] = useState(0)
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      statusRus: '',
      facilityName: '',
      typeTenderRus: '',
      dateConfirmStart: '',
      dateConfirmEnd: '',
      dateOfferEnd: '',
      dateSummingEnd: '',
    }, { name: formName }))
    dispatch(actions.formInitialize({ byId: {}, allIds: [], }, { name: formContacts }))
    dispatch(actions.formInitialize({ projectsArr: [], projectsObj: {}, estimateObj: {} }, { name: formLots }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formContacts }))
      dispatch(actions.formDestroy({ name: formLots }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getTenderById,
      param: {
        id: match.params.idDoc
      }
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        facilityName: res.get.facilityName,
        dateConfirmStart: moment(res.get.dateConfirmStart).format('LLL'),
        dateConfirmEnd: moment(res.get.dateConfirmEnd).format('LLL'),
        dateOfferEnd: moment(res.get.dateOfferEnd).format('LLL'),
        dateSummingEnd: moment(res.get.dateSummingEnd).format('LLL'),
        typeTenderRus: res.get.typeTender === 'open' ? 'открытый' : 'закрытый',
        statusRus: TENDER_STATUS.find(s => s.value === res.get.status).label,
        regionsArr: res.get.regions,
        regions: res.get.regions.join(', '),
        specializationsArr: res.get.specializations,
        specializations: res.get.specializations.join(', '),
        statusBid: res.get.statusBid,
        statusBidRus: TENDER_STATUS_BID.find(s => s.value === res.get.statusBid).label,
      }, { name: formName }))
      let byId = {}
      const allIds = res.get.contacts.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))
      const { estimate, projects } = res.get.estimate;
      const projectsArr = []
      let projectsObj = {}
      projects.forEach(obj => {
        projectsArr.push(obj.id)
        let groupObj = {}
        const groupArr = obj.group.map(e => {
          groupObj[e.id] = { ...e, child: [] };
          return e.id
        })
        projectsObj[obj.id] = {
          id: obj.id,
          dateStart: moment(obj.dateStart).format('YYYY-MM-DD'),
          dateEnd: moment(obj.dateEnd).format('YYYY-MM-DD'),
          name: obj.name,
          group: {
            arr: groupArr,
            obj: groupObj,
          }
        }
      })
      let estimateObj = {}
      estimate.forEach(e => {
        const chooseDateStart = e.chooseDateStart ? moment(e.chooseDateStart).format('YYYY-MM-DD') : ''
        const chooseDateEnd = e.chooseDateEnd ? moment(e.chooseDateEnd).format('YYYY-MM-DD') : ''
        let choose = {
          chooseExist: e.chooseExist,
          chooseDateStart: chooseDateStart === '' ? moment(e.dateStart).format('YYYY-MM-DD') : chooseDateStart,
          chooseDateEnd: chooseDateEnd === '' ? moment(e.dateEnd).format('YYYY-MM-DD') : chooseDateEnd,
          choosePrice: e.choosePrice || 0
        }
        estimateObj[e.id] = {
          ...e,
          dateStart: moment(e.dateStart).format('DD.MM.YYYY'),
          dateEnd: moment(e.dateEnd).format('DD.MM.YYYY'),
          typeEstimate: e.typeEstimate === 'material' ? 'М' : 'Р',
          ...choose
        }
        projectsObj[e.idProject]?.group.obj[e.idGroup]?.child.push(e.id);
      })
      dispatch(actions.formChangeAsObj({ projectsArr, projectsObj, estimateObj }, { name: formLots }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])

  return <div className='App-paper'>
    <HederProps idDoc={match.params.idDoc} />
    <Tabs value={tabsId} onChange={(e, v) => setTabsId(v)} indicatorColor='primary'>
      <Tab label='Контакты' />
      <Tab label='Лоты' />
      <Tab label='Создать' />
    </Tabs>
    {tabsId === 0 && <Contacts />}
    {tabsId === 1 && <Lots match={match} />}
    {tabsId === 2 && <ProjectsCreate idDoc={match.params.idDoc} />}
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}

function ProjectsCreate({ idDoc }) {
  const [objectName, setObjectName] = useState('')
  const dispatch = useDispatch()
  const handleCreate = () => {
    dispatch(fetchDispath({
      progress: true,
      request: createTenderProject,
      param: {
        id: idDoc
      },
      body: { objectName }
    })).then((res) => {
    })
  }
  return <div>
    <TextField id="standard-basic" label="Наименование объекта" onChange={(e) => setObjectName(e.target.value)} />
    <div></div>
    <Button color="primary" onClick={handleCreate}>Создать </Button>
  </div>
}

const headerCol = [
  { id: 'name', name: 'Название' },
  { id: 'statusRus', name: 'Статус' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', },
  { id: 'regions', name: 'Регионы', },
  { id: 'specializations', name: 'Специализация', },
]
function HederProps({ idDoc }) {
  const values = useSelector(state => state.form[formName]?.values) || {}
  const dispatch = useDispatch()
  const handleSetOrder = () => {
    dispatch(fetchDispath({
      progress: true,
      request: setTenderBid,
      param: {
        id: idDoc,
      },
    })).then(() => {
    })
  }
  return <div>
    {headerCol.map(obj => {
      return <div key={obj.id}>{obj.name}:  {values[obj.id]}</div>
    })}
    <Typography style={{ fontWeight: 'bold' }}>Статус заявки: {values.statusBidRus}</Typography>
    {values.statusBid === 'none' && <Button color="primary" onClick={handleSetOrder}>Подать заявку</Button>}
  </div>
}
const contactsCol = [
  // { id: 'name', type: 'text', name: 'Имя' },
  // { id: 'surname', type: 'text', name: 'Фамилия' },
  // { id: 'patronymic', type: 'text', name: 'Отчество' },
  { id: 'fio', type: 'text', name: 'Имя' },
  { id: 'email', type: 'text', name: 'Почта' },
  { id: 'phone', type: 'text', name: 'Телефон' },
  { id: 'jobTitle', type: 'text', name: 'Должность' },
  { id: 'additionalContacts', type: 'text', name: 'Доп. контакт' },
]
function Contacts() {
  const values = useSelector(state => state.form[formContacts]?.values) || { byId: {}, allIds: [] }
  return <div>
    {values.allIds.map((key, i) => {
      return <div key={key}>
        <Typography>{i + 1}</Typography>
        {contactsCol.map(obj => {
          return <div key={obj.id}>{obj.name}:  {values.byId[key][obj.id]}</div>
        })}
      </div>
    })}
  </div>
}
const tableHead = [
  { name: 'Номер', id: 'number' },
  { name: 'Тип', id: 'typeEstimate' },
  { name: 'Наименование', id: 'name' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество', align: 'right', id: 'count' },
  { name: 'Дата начала', align: 'right', id: 'dateStart' },
  { name: 'Дата окончания', align: 'right', id: 'dateEnd' },
]
function Lots({ match }) {
  const values = useSelector(state => state.form[formLots]?.values) || { projectsArr: [], projectsObj: {}, estimateObj: {} }
  return <div>
    {values.projectsArr.map(key => {
      const project = values.projectsObj[key]
      return <div key={key} style={{ marginBottom: 20 }}>
        <Typography variant='h6'>{project.name}</Typography>
        <div>Дата работ с:  {project.dateStart} по:  {project.dateEnd}</div>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {tableHead.map(obj => {
                const align = obj.align || 'inherit'
                return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
              })}
              <TableCell >Цена</TableCell>
              <TableCell >Дата начала</TableCell>
              <TableCell >Дата окончания</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {project.group.arr.map(groupKey => {
              return <Row
                key={groupKey}
                obj={project.group.obj[groupKey]}
                child={project.group.obj[groupKey].child}
                estimate={values.estimateObj}
                idTender={match.params.idDoc}
              />
            })}
          </TableBody>
        </Table>
      </div>
    })}
  </div>
}

function Row({ obj, child, estimate, idTender }) {
  const [show, changeShow] = useState(true)
  const dispatch = useDispatch()
  console.log({ obj, child, estimate, idTender })
  const handleChangePrice = (est) => {
    dispatch(fetchDispath({
      progress: true,
      request: updTenderEstimate,
      param: {
        id: idTender,
        idEstimate: est.id
      },
      body: {
        exist: est.chooseExist,
        price: est.choosePrice,
        dateStart: est.chooseDateStart,
        dateEnd: est.chooseDateEnd,
      }
    })).then((res) => {
      if (res.success) {
        dispatch(actions.formObjChangeObj({
          chooseExist: true
        }, { field: 'estimateObj', id: est.id, name: formLots }))
      }
    })
  }
  const handleChange = (value, field, id) => {
    dispatch(actions.formObjChangeObj({
      [field]: value
    }, { field: 'estimateObj', id: id, name: formLots }))
  }
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#eee' }} onClick={() => changeShow(!show)}>
        <TableCell>{obj.numberPos}</TableCell>
        <TableCell colSpan={9}>{obj.name}</TableCell>
      </TableRow>
      {
        show && child.map((id, i) => {
          const est = { ...estimate[id], number: obj.numberPos + '.' + (i + 1) };
          return <TableRow key={id} >
            {tableHead.map(e => {
              return <TableCell key={e.id} align={e.align}>{est[e.id]}</TableCell>
            })}
            <TableCell ><Input
              type='number'
              value={est.choosePrice}
              onChange={(e) => {
                handleChange(e.target.value, 'choosePrice', est.id)
              }}
              onSelect={(e) => e.target.select()}
              onBlur={() => handleChangePrice(est)}
            /></TableCell>
            <TableCell ><Input
              type='date'
              value={est.chooseDateStart}
              onChange={(e) => {
                handleChange(e.target.value, 'chooseDateStart', est.id)
              }}
              onBlur={() => handleChangePrice(est)}
            /></TableCell>
            <TableCell ><Input
              type='date'
              value={est.chooseDateEnd}
              onChange={(e) => {
                handleChange(e.target.value, 'chooseDateEnd', est.id)
              }}
              onBlur={() => handleChangePrice(est)}
            /></TableCell>
          </TableRow>
        })}
    </React.Fragment>
  );
}