import template from "./template_crud";
const app='/app_worker'
export const loginWorker = template.addOne(app+'/login')
export const getMe = template.get(app+'/me')
export const getDriver = template.get(app+'/driver')
export const getDriverCurrent = template.get(app+'/driver-current')

const waybill = app+'/waybill'
export const getWaybillById = template.getById(waybill);
export const updMachineStart = template.updById(waybill,'/start');
export const updMachineEnd = template.updById(waybill,'/stop');

//checklist
export const getMachineCheckList = template.getById(waybill,'/c_list');
export const startMachineCheckList = {
    path: (obj) => `${waybill}/${obj.id}/c_list/${obj.idChl}/start`,
    type: 'POST'
};
export const getCheckListWBId =  {
    path: (obj) => `${waybill}/${obj.id}/c_list/${obj.idChWB}`,
    type: 'GET'
}
export const updCheckListWBIdAnswer =  {
    path: (obj) => `${waybill}/${obj.id}/c_list/${obj.idChWB}/answer`,
    type: 'PUT'
}
export const updCheckListWBIdStop =  {
    path: (obj) => `${waybill}/${obj.id}/c_list/${obj.idChWB}/stop`,
    type: 'PUT'
}

export const getMachineTask = template.getById(waybill,'/task');
export const updMachineTaskResult = template.updById(waybill,'/task_result');
export const removeMachineTaskResult = template.updById(waybill,'/task_result_remove');
export const updMachineTaskFinish = template.updById(waybill,'/task_finish');
