import template from "./template_crud";

// tender-our
const tendersOur = '/tenders_our'
export const getTenderOur = template.getList(tendersOur);
export const addTenderOur = template.addOne(tendersOur)
export const getTenderOurById = template.getById(tendersOur)
export const updTenderOur = template.updById(tendersOur)
export const deleteTenderOur = template.deleteById(tendersOur)
export const setTenderOurBid = {
    path: (obj) => `${tendersOur}/${obj.id}/bid`,
    type: 'PUT'
}
// estimate 
export const addTenderOurLot = {
    path: (obj) => `${tendersOur}/${obj.id}/estimate`,
    type: 'POST'
}
export const setTenderStatus = {
    path: (obj) => `${tendersOur}/${obj.id}/status`,
    type: 'PUT'
}
export const delTenderOurLot = {
    path: (obj) => `${tendersOur}/${obj.id}/estimate/${obj.idProject}`,
    type: 'DELETE'
}

// tender-our
const tenders = '/tenders'
export const getTender = template.getList(tenders);
export const getTenderById = template.getById(tenders)
export const setTenderBid = {
    path: (obj) => `${tenders}/${obj.id}/bid`,
    type: 'PUT'
}
export const createTenderProject = {
    path: (obj) => `${tenders}/${obj.id}/create_object`,
    type: 'POST'
}
export const updTenderEstimate = {
    path: (obj) => `${tenders}/${obj.id}/estimate/${obj.idEstimate}`,
    type: 'PUT'
}